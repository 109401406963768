/** Offer Table **/

.offer-table-cell {
  border-right: 1px solid rgba(224, 224, 224, 1);
}

.offer-table {
  width: max-content !important;
  min-width: 100%;
}

.template-border {
  border: 1px solid rgba(224, 224, 224, 1) !important;
}

.offer-name-container {
  width: 14em;
  max-width: 14em;
}

.offer-deal-container {
  max-width: 14em;
}

/** Offer Action Button **/

.action-button {
  font-weight: 400 !important;
  color: gray !important;
  font-size: 0.6em !important;
  font-weight: bold;
}

.offer-row:hover .action-button {
  display: inline-flex !important;
}
/** Search input **/

.search-input:focus {
  border-color: rgba(224, 224, 224, 1) !important;
}

/** Table Pagination **/

.number-slot-idle {
  color: #4896f4;
}
